export const SCOPE = `PMK/ResponsiblePerson`;


export const RESPONSIBLE_PERSON_LIST_GETTER = `${SCOPE}/RESPONSIBLE_PERSON_LIST_GETTER`;
export const RESPONSIBLE_PERSONS_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const RESPONSIBLE_PERSON_GETTER = `${SCOPE}/user`;
export const NEW_RESPONSIBLE_PERSON_GETTER = `${SCOPE}/newResponsiblePerson`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_RESPONSIBLE_PERSONS_ACTION = `${SCOPE}/fetchAllResponsiblePersons`;
export const FETCH_DETAIL_RESPONSIBLE_PERSON_ACTION = `${SCOPE}/fetchDetailResponsiblePerson`;
export const STORE_RESPONSIBLE_PERSON_ACTION = `${SCOPE}/storeResponsiblePerson`;
export const UPDATE_RESPONSIBLE_PERSON_ACTION = `${SCOPE}/updateResponsiblePerson`;
export const DELETE_RESPONSIBLE_PERSON_ACTION = `${SCOPE}/deleteResponsiblePerson`;
export const ADD_RESPONSIBLE_PERSON_INPUT_ACTION = `${SCOPE}/addResponsiblePersonInput`;
export const UPDATE_RESPONSIBLE_PERSON_INPUT_ACTION = `${SCOPE}/updateResponsiblePersonInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_RESPONSIBLE_PERSONS_MUTATION = `${SCOPE}/setResponsiblePersons`;
export const SET_RESPONSIBLE_PERSONS_PAGINATED_MUTATION = `${SCOPE}/setResponsiblePersonsPaginated`;
export const SET_RESPONSIBLE_PERSON_DETAIL_MUTATION = `${SCOPE}/setResponsiblePersonDetail`;
export const SET_RESPONSIBLE_PERSON_ARE_LOADING_MUTATION = `${SCOPE}/setResponsiblePersonsAreLoading`;
export const SET_RESPONSIBLE_PERSONS_LOADED_MUTATION = `${SCOPE}/setResponsiblePersonsLoaded`;
export const SET_RESPONSIBLE_PERSON_IS_LOADING_MUTATION = `${SCOPE}/setResponsiblePersonIsLoading`;
export const SAVE_NEW_RESPONSIBLE_PERSONS_MUTATION = `${SCOPE}/saveNewResponsiblePersons`;
export const SET_RESPONSIBLE_PERSON_IS_CREATING_MUTATION = `${SCOPE}/setResponsiblePersonIsCreating`;
export const SAVE_UPDATED_RESPONSIBLE_PERSON_MUTATION = `${SCOPE}/saveUpdatedResponsiblePerson`;
export const SET_RESPONSIBLE_PERSON_IS_UPDATING_MUTATION = `${SCOPE}/setResponsiblePersonIsUpdating`;
export const SET_RESPONSIBLE_PERSON_ADD_INPUT_MUTATION = `${SCOPE}/setResponsiblePersonAddInput`;
export const SET_RESPONSIBLE_PERSON_DETAIL_INPUT_MUTATION = `${SCOPE}/setResponsiblePersonDetailInput`;
export const SET_RESPONSIBLE_PERSON_IS_DELETING_MUTATION = `${SCOPE}/setResponsiblePersonIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
