<div class="wrapper">
    <app-header @toggle-menu-sidebar="toggleMenuSidebar"></app-header>
    <menu-sidebar></menu-sidebar>
    <div class="content-wrapper">
        <router-view></router-view>
    </div>
    <app-footer></app-footer>
    <div
            id="sidebar-overlay"
            v-if="screenSize === 'xs' && isSidebarMenuCollapsed"
            @click="toggleMenuSidebar"
    ></div>
</div>
<div class="modal-overlay" style="display: block;" v-if="isModalActive" @click="setModalActive"></div>