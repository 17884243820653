export const SCOPE = `PMK/User`;


export const USER_LIST_GETTER = `${SCOPE}/USER_LIST_GETTER`;
export const USERS_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const USER_GETTER = `${SCOPE}/user`;
export const NEW_USER_GETTER = `${SCOPE}/newUser`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_USERS_ACTION = `${SCOPE}/fetchAllUsers`;
export const FETCH_DETAIL_USER_ACTION = `${SCOPE}/fetchDetailUser`;
export const STORE_USER_ACTION = `${SCOPE}/storeUser`;
export const UPDATE_USER_ACTION = `${SCOPE}/updateUser`;
export const DELETE_USER_ACTION = `${SCOPE}/deleteUser`;
export const ADD_USER_INPUT_ACTION = `${SCOPE}/addUserInput`;
export const UPDATE_USER_INPUT_ACTION = `${SCOPE}/updateUserInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_USERS_MUTATION = `${SCOPE}/setUsers`;
export const SET_USERS_PAGINATED_MUTATION = `${SCOPE}/setUsersPaginated`;
export const SET_USER_DETAIL_MUTATION = `${SCOPE}/setUserDetail`;
export const SET_USER_ARE_LOADING_MUTATION = `${SCOPE}/setUsersAreLoading`;
export const SET_USERS_LOADED_MUTATION = `${SCOPE}/setUsersLoaded`;
export const SET_USER_IS_LOADING_MUTATION = `${SCOPE}/setUserIsLoading`;
export const SAVE_NEW_USERS_MUTATION = `${SCOPE}/saveNewUsers`;
export const SET_USER_IS_CREATING_MUTATION = `${SCOPE}/setUserIsCreating`;
export const SAVE_UPDATED_USER_MUTATION = `${SCOPE}/saveUpdatedUser`;
export const SET_USER_IS_UPDATING_MUTATION = `${SCOPE}/setUserIsUpdating`;
export const SET_USER_ADD_INPUT_MUTATION = `${SCOPE}/setUserAddInput`;
export const SET_USER_DETAIL_INPUT_MUTATION = `${SCOPE}/setUserDetailInput`;
export const SET_USER_IS_DELETING_MUTATION = `${SCOPE}/setUserIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
