export const SCOPE = `PMK/ProcurementOfficer`;


export const PROCUREMENT_OFFICER_LIST_GETTER = `${SCOPE}/PROCUREMENT_OFFICER_LIST_GETTER`;
export const PROCUREMENT_OFFICERS_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const PROCUREMENT_OFFICER_GETTER = `${SCOPE}/user`;
export const NEW_PROCUREMENT_OFFICER_GETTER = `${SCOPE}/newProcurementOfficer`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_PROCUREMENT_OFFICERS_ACTION = `${SCOPE}/fetchAllProcurementOfficers`;
export const FETCH_DETAIL_PROCUREMENT_OFFICER_ACTION = `${SCOPE}/fetchDetailProcurementOfficer`;
export const STORE_PROCUREMENT_OFFICER_ACTION = `${SCOPE}/storeProcurementOfficer`;
export const UPDATE_PROCUREMENT_OFFICER_ACTION = `${SCOPE}/updateProcurementOfficer`;
export const DELETE_PROCUREMENT_OFFICER_ACTION = `${SCOPE}/deleteProcurementOfficer`;
export const ADD_PROCUREMENT_OFFICER_INPUT_ACTION = `${SCOPE}/addProcurementOfficerInput`;
export const UPDATE_PROCUREMENT_OFFICER_INPUT_ACTION = `${SCOPE}/updateProcurementOfficerInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_PROCUREMENT_OFFICERS_MUTATION = `${SCOPE}/setProcurementOfficers`;
export const SET_PROCUREMENT_OFFICERS_PAGINATED_MUTATION = `${SCOPE}/setProcurementOfficersPaginated`;
export const SET_PROCUREMENT_OFFICER_DETAIL_MUTATION = `${SCOPE}/setProcurementOfficerDetail`;
export const SET_PROCUREMENT_OFFICER_ARE_LOADING_MUTATION = `${SCOPE}/setProcurementOfficersAreLoading`;
export const SET_PROCUREMENT_OFFICERS_LOADED_MUTATION = `${SCOPE}/setProcurementOfficersLoaded`;
export const SET_PROCUREMENT_OFFICER_IS_LOADING_MUTATION = `${SCOPE}/setProcurementOfficerIsLoading`;
export const SAVE_NEW_PROCUREMENT_OFFICERS_MUTATION = `${SCOPE}/saveNewProcurementOfficers`;
export const SET_PROCUREMENT_OFFICER_IS_CREATING_MUTATION = `${SCOPE}/setProcurementOfficerIsCreating`;
export const SAVE_UPDATED_PROCUREMENT_OFFICER_MUTATION = `${SCOPE}/saveUpdatedProcurementOfficer`;
export const SET_PROCUREMENT_OFFICER_IS_UPDATING_MUTATION = `${SCOPE}/setProcurementOfficerIsUpdating`;
export const SET_PROCUREMENT_OFFICER_ADD_INPUT_MUTATION = `${SCOPE}/setProcurementOfficerAddInput`;
export const SET_PROCUREMENT_OFFICER_DETAIL_INPUT_MUTATION = `${SCOPE}/setProcurementOfficerDetailInput`;
export const SET_PROCUREMENT_OFFICER_IS_DELETING_MUTATION = `${SCOPE}/setProcurementOfficerIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
