// import { mapGetters } from "vuex";
import Button from '@/Components/Button/Button.vue';

export default {
    name: "Login",
    components: {
        'app-button': Button
    },
    data() {
        return {
            appElement: null,
            email: null,
            password: null,
            remember_me: false,
        }
    },
    mounted() {
        this.appElement = document.getElementsByTagName('body')[0];
        this.appElement.classList.add('login-page');
    },
    unmounted() {
        this.appElement.classList.remove('login-page');
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isLoading() {
            return this.$store.state.auth.status.isLoading;
        },
        errorStatus() {
            return this.$store.state.auth.error.status;
        },
        errorMessage() {
            return this.$store.state.auth.error.message;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/admin");
        }
    },
    methods: {
        handleLogin() {
            const user = {
                email: this.email,
                password: this.password,
                remember_me: this.remember_me
            };

            this.$store.dispatch("auth/login", user).then(
                () => {
                    this.$swal.fire({
                        text: "You are Logged in.",
                        icon: "success",
                        timer: 10000,
                    });
                    this.$router.push("/admin");
                },
                () => {
                    // console.log(error.message);
                }
            );
        },
    },
}