export const SCOPE = `PMK/Permission`;


export const PERMISSION_LIST_GETTER = `${SCOPE}/PERMISSION_LIST_GETTER`;
export const PERMISSIONS_PAGINATED_DATA_GETTER = `${SCOPE}/permissionsPaginatedData`;
export const PERMISSION_GETTER = `${SCOPE}/permission`;
export const NEW_PERMISSION_GETTER = `${SCOPE}/newPermission`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_PERMISSIONS_ACTION = `${SCOPE}/fetchAllPermissions`;
export const FETCH_DETAIL_PERMISSION_ACTION = `${SCOPE}/fetchDetailPermission`;
export const STORE_PERMISSION_ACTION = `${SCOPE}/storePermission`;
export const UPDATE_PERMISSION_ACTION = `${SCOPE}/updatePermission`;
export const DELETE_PERMISSION_ACTION = `${SCOPE}/deletePermission`;
export const ADD_PERMISSION_INPUT_ACTION = `${SCOPE}/addPermissionInput`;
export const UPDATE_PERMISSION_INPUT_ACTION = `${SCOPE}/updatePermissionInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_PERMISSIONS_MUTATION = `${SCOPE}/setPermissions`;
export const SET_PERMISSIONS_PAGINATED_MUTATION = `${SCOPE}/setPermissionsPaginated`;
export const SET_PERMISSION_DETAIL_MUTATION = `${SCOPE}/setPermissionDetail`;
export const SET_PERMISSION_ARE_LOADING_MUTATION = `${SCOPE}/setPermissionsAreLoading`;
export const SET_PERMISSIONS_LOADED_MUTATION = `${SCOPE}/setPermissionsLoaded`;
export const SET_PERMISSION_IS_LOADING_MUTATION = `${SCOPE}/setPermissionIsLoading`;
export const SAVE_NEW_PERMISSIONS_MUTATION = `${SCOPE}/saveNewPermissions`;
export const SET_PERMISSION_IS_CREATING_MUTATION = `${SCOPE}/setPermissionIsCreating`;
export const SAVE_UPDATED_PERMISSION_MUTATION = `${SCOPE}/saveUpdatedPermission`;
export const SET_PERMISSION_IS_UPDATING_MUTATION = `${SCOPE}/setPermissionIsUpdating`;
export const SET_PERMISSION_ADD_INPUT_MUTATION = `${SCOPE}/setPermissionAddInput`;
export const SET_PERMISSION_DETAIL_INPUT_MUTATION = `${SCOPE}/setPermissionDetailInput`;
export const SET_PERMISSION_IS_DELETING_MUTATION = `${SCOPE}/setPermissionIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
