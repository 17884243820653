import MenuItem from '../../../Components/MenuItem/MenuItem.vue';

export default {
    name: "MenuSidebar",
    components: {
        'app-menu-item': MenuItem
    },
    computed: {
        user() {
            // return this.$store.state.auth.user.user;
            return this.$store.getters['auth/user']
        },
        rolePermissions() {
            return this.$store.getters['auth/rolePermissions']
        },
        directPermissions() {
            return this.$store.getters['auth/directPermissions']
        }
    },
    data() {
        let MENU = [
            // {
            //     name: 'Dashboard',
            //     path: '/admin',
            //     icon: 'fas fa-tachometer-alt',
            //     permission: 'Dashboard Show'
            // },
            {
                name: 'Companies',
                path: '/admin/companies',
                icon: 'fas fa-building',
                permission: 'Company Show All'
            },
            {
                name: 'Departments',
                path: '/admin/departments',
                icon: 'fas fa-landmark',
                permission: 'Departments Show All'
            },
            {
                name: 'Request Contracts',
                path: '/admin/contracts',
                icon: 'fas fa-file-contract',
                permission: 'Contract Show All'
            },
            {
                name: 'Archive Contracts',
                path: '/admin/contracts/archive',
                icon: 'fas fa-file-contract',
                permission: 'Contracts Archive'
            },
            {
                name: 'Contract Type',
                path: '/admin/contract_types',
                icon: 'fas fa-copy',
                permission: 'Contract Type Show All'
            },
            // {
            //     name: 'Responsible Persons',
            //     path: '/admin/users/responsible_persons',
            //     icon: 'fas fa-user-friends',
            //     permission: 'Responsible Person Show All'
            // },
            // {
            //     name: 'Procurement Officer',
            //     path: '/admin/users/procurement_officer',
            //     icon: 'fas fa-user-tag',
            //     permission: 'Procurement Officer Show All'
            // },
            // {
            //     name: 'Director of Departments',
            //     path: '/admin/users/director_departments',
            //     icon: 'fas fa-user-shield',
            //     permission: 'Director Department Show All'
            // },
            // {
            //     name: 'Executive Director',
            //     path: '/admin/users/executive_director',
            //     icon: 'fas fa-user-check',
            //     permission: 'Executive Director Show All'
            // },
            // {
            //     name: 'Legal Office',
            //     path: '/admin/users/legal_office',
            //     icon: 'fas fa-chalkboard-teacher',
            //     permission: 'Legal Office Show All'
            // },
            {
                name: 'All users',
                path: '/admin/users',
                icon: 'fas fa-users',
                permission: 'Users Show All'
            },
            {
                name: 'Roles',
                path: '/admin/roles',
                icon: 'fas fa-unlock',
                permission: 'Roles Show All',
            },
            {
                name: 'Permissions',
                path: '/admin/permissions',
                icon: 'fas fa-lock',
                permission: 'Permissions Show All'
            },
            {
                name: 'Settings',
                path: '/admin/blank',
                icon: 'fas fa-cog',
                permission: 'Settings Show All'
            },
        ];
        return {
            menu: MENU,
        }
    },
    methods: {
        permissions() {
            let menu1 = [];
            let menu2 = [];
            if (this.rolePermissions !== null && this.menu !== null) {
                this.menu.forEach(m => {
                    let p = this.rolePermissions.filter(p => p.name === m.permission)
                    if (p.length >= 1) {
                        menu1.push(m);
                    }
                })
            }
            if (this.directPermissions !== undefined) {
                if (this.directPermissions !== null && this.menu !== null) {
                    this.menu.forEach(m => {
                        let p = this.directPermissions.filter(p => p.name === m.permission)
                        if (p.length >= 1) {
                            menu2.push(m);
                        }
                    })
                }
            }
            this.menu = menu1.concat(menu2)
            this.menu.unshift({
                name: 'Dashboard',
                path: '/admin',
                icon: 'fas fa-tachometer-alt',
                permission: 'Dashboard Show'
            })
        }
    },
    created() {
      this.permissions()
    },
    // watch: {
        // rolePermissions: function () {
            // console.log(this.rolePermissions());
            // let per1 = []
            // this.permissions.forEach(p => {
            //     per1.push(this.menu.filter(m => m.permission === p.name))
            // })
            // console.log(per1)
        // }
    // }
}