export const SCOPE = `PMK/Company`;


export const COMPANY_LIST_GETTER = `${SCOPE}/COMPANY_LIST_GETTER`;
export const COMPANIES_PAGINATED_DATA_GETTER = `${SCOPE}/companiesPaginatedData`;
export const COMPANY_GETTER = `${SCOPE}/company`;
export const NEW_COMPANY_GETTER = `${SCOPE}/newCompany`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_COMPANIES_ACTION = `${SCOPE}/fetchAllCompanies`;
export const FETCH_DETAIL_COMPANY_ACTION = `${SCOPE}/fetchDetailCompany`;
export const STORE_COMPANY_ACTION = `${SCOPE}/storeCompany`;
export const UPDATE_COMPANY_ACTION = `${SCOPE}/updateCompany`;
export const DELETE_COMPANY_ACTION = `${SCOPE}/deleteCompany`;
export const ADD_COMPANY_INPUT_ACTION = `${SCOPE}/addCompanyInput`;
export const UPDATE_COMPANY_INPUT_ACTION = `${SCOPE}/updateCompanyInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_COMPANIES_MUTATION = `${SCOPE}/setCompanies`;
export const SET_COMPANIES_PAGINATED_MUTATION = `${SCOPE}/setCompaniesPaginated`;
export const SET_COMPANY_DETAIL_MUTATION = `${SCOPE}/setCompanyDetail`;
export const SET_COMPANY_ARE_LOADING_MUTATION = `${SCOPE}/setCompaniesAreLoading`;
export const SET_COMPANIES_LOADED_MUTATION = `${SCOPE}/setCompaniesLoaded`;
export const SET_COMPANY_IS_LOADING_MUTATION = `${SCOPE}/setCompanyIsLoading`;
export const SAVE_NEW_COMPANIES_MUTATION = `${SCOPE}/saveNewCompanies`;
export const SET_COMPANY_IS_CREATING_MUTATION = `${SCOPE}/setCompanyIsCreating`;
export const SAVE_UPDATED_COMPANY_MUTATION = `${SCOPE}/saveUpdatedCompany`;
export const SET_COMPANY_IS_UPDATING_MUTATION = `${SCOPE}/setCompanyIsUpdating`;
export const SET_COMPANY_ADD_INPUT_MUTATION = `${SCOPE}/setCompanyAddInput`;
export const SET_COMPANY_DETAIL_INPUT_MUTATION = `${SCOPE}/setCompanyDetailInput`;
export const SET_COMPANY_IS_DELETING_MUTATION = `${SCOPE}/setCompanyIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
