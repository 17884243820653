export const SCOPE = `PMK/Role`;


export const ROLE_LIST_GETTER = `${SCOPE}/ROLE_LIST_GETTER`;
export const ROLES_PAGINATED_DATA_GETTER = `${SCOPE}/permissionsPaginatedData`;
export const ROLE_GETTER = `${SCOPE}/permission`;
export const NEW_ROLE_GETTER = `${SCOPE}/newRole`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_ROLES_ACTION = `${SCOPE}/fetchAllRoles`;
export const FETCH_DETAIL_ROLE_ACTION = `${SCOPE}/fetchDetailRole`;
export const STORE_ROLE_ACTION = `${SCOPE}/storeRole`;
export const UPDATE_ROLE_ACTION = `${SCOPE}/updateRole`;
export const DELETE_ROLE_ACTION = `${SCOPE}/deleteRole`;
export const ADD_ROLE_INPUT_ACTION = `${SCOPE}/addRoleInput`;
export const UPDATE_ROLE_INPUT_ACTION = `${SCOPE}/updateRoleInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_ROLES_MUTATION = `${SCOPE}/setRoles`;
export const SET_ROLES_PAGINATED_MUTATION = `${SCOPE}/setRolesPaginated`;
export const SET_ROLE_DETAIL_MUTATION = `${SCOPE}/setRoleDetail`;
export const SET_ROLE_ARE_LOADING_MUTATION = `${SCOPE}/setRolesAreLoading`;
export const SET_ROLES_LOADED_MUTATION = `${SCOPE}/setRolesLoaded`;
export const SET_ROLE_IS_LOADING_MUTATION = `${SCOPE}/setRoleIsLoading`;
export const SAVE_NEW_ROLES_MUTATION = `${SCOPE}/saveNewRoles`;
export const SET_ROLE_IS_CREATING_MUTATION = `${SCOPE}/setRoleIsCreating`;
export const SAVE_UPDATED_ROLE_MUTATION = `${SCOPE}/saveUpdatedRole`;
export const SET_ROLE_IS_UPDATING_MUTATION = `${SCOPE}/setRoleIsUpdating`;
export const SET_ROLE_ADD_INPUT_MUTATION = `${SCOPE}/setRoleAddInput`;
export const SET_ROLE_DETAIL_INPUT_MUTATION = `${SCOPE}/setRoleDetailInput`;
export const SET_ROLE_IS_DELETING_MUTATION = `${SCOPE}/setRoleIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
