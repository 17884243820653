export const SCOPE = `PMK/DirectorDepartment`;


export const DIRECTOR_DEPARTMENT_LIST_GETTER = `${SCOPE}/DIRECTOR_DEPARTMENT_LIST_GETTER`;
export const DIRECTOR_DEPARTMENTS_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const DIRECTOR_DEPARTMENT_GETTER = `${SCOPE}/user`;
export const NEW_DIRECTOR_DEPARTMENT_GETTER = `${SCOPE}/newDirectorDepartment`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_DIRECTOR_DEPARTMENTS_ACTION = `${SCOPE}/fetchAllDirectorDepartments`;
export const FETCH_DETAIL_DIRECTOR_DEPARTMENT_ACTION = `${SCOPE}/fetchDetailDirectorDepartment`;
export const STORE_DIRECTOR_DEPARTMENT_ACTION = `${SCOPE}/storeDirectorDepartment`;
export const UPDATE_DIRECTOR_DEPARTMENT_ACTION = `${SCOPE}/updateDirectorDepartment`;
export const DELETE_DIRECTOR_DEPARTMENT_ACTION = `${SCOPE}/deleteDirectorDepartment`;
export const ADD_DIRECTOR_DEPARTMENT_INPUT_ACTION = `${SCOPE}/addDirectorDepartmentInput`;
export const UPDATE_DIRECTOR_DEPARTMENT_INPUT_ACTION = `${SCOPE}/updateDirectorDepartmentInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_DIRECTOR_DEPARTMENTS_MUTATION = `${SCOPE}/setDirectorDepartments`;
export const SET_DIRECTOR_DEPARTMENTS_PAGINATED_MUTATION = `${SCOPE}/setDirectorDepartmentsPaginated`;
export const SET_DIRECTOR_DEPARTMENT_DETAIL_MUTATION = `${SCOPE}/setDirectorDepartmentDetail`;
export const SET_DIRECTOR_DEPARTMENT_ARE_LOADING_MUTATION = `${SCOPE}/setDirectorDepartmentsAreLoading`;
export const SET_DIRECTOR_DEPARTMENTS_LOADED_MUTATION = `${SCOPE}/setDirectorDepartmentsLoaded`;
export const SET_DIRECTOR_DEPARTMENT_IS_LOADING_MUTATION = `${SCOPE}/setDirectorDepartmentIsLoading`;
export const SAVE_NEW_DIRECTOR_DEPARTMENTS_MUTATION = `${SCOPE}/saveNewDirectorDepartments`;
export const SET_DIRECTOR_DEPARTMENT_IS_CREATING_MUTATION = `${SCOPE}/setDirectorDepartmentIsCreating`;
export const SAVE_UPDATED_DIRECTOR_DEPARTMENT_MUTATION = `${SCOPE}/saveUpdatedDirectorDepartment`;
export const SET_DIRECTOR_DEPARTMENT_IS_UPDATING_MUTATION = `${SCOPE}/setDirectorDepartmentIsUpdating`;
export const SET_DIRECTOR_DEPARTMENT_ADD_INPUT_MUTATION = `${SCOPE}/setDirectorDepartmentAddInput`;
export const SET_DIRECTOR_DEPARTMENT_DETAIL_INPUT_MUTATION = `${SCOPE}/setDirectorDepartmentDetailInput`;
export const SET_DIRECTOR_DEPARTMENT_IS_DELETING_MUTATION = `${SCOPE}/setDirectorDepartmentIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
