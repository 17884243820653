export const SCOPE = `PMK/Contract`;


export const CONTRACT_LIST_GETTER = `${SCOPE}/CONTRACT_LIST_GETTER`;
export const CONTRACTS_PAGINATED_DATA_GETTER = `${SCOPE}/departmentsPaginatedData`;
export const CONTRACT_GETTER = `${SCOPE}/department`;
export const NEW_CONTRACT_GETTER = `${SCOPE}/newContract`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_CONTRACTS_ACTION = `${SCOPE}/fetchAllContracts`;
export const FETCH_DETAIL_CONTRACT_ACTION = `${SCOPE}/fetchDetailContract`;
export const STORE_CONTRACT_ACTION = `${SCOPE}/storeContract`;
export const UPDATE_CONTRACT_ACTION = `${SCOPE}/updateContract`;
export const DELETE_CONTRACT_ACTION = `${SCOPE}/deleteContract`;
export const ADD_CONTRACT_INPUT_ACTION = `${SCOPE}/addContractInput`;
export const UPDATE_CONTRACT_INPUT_ACTION = `${SCOPE}/updateContractInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_CONTRACTS_MUTATION = `${SCOPE}/setContracts`;
export const SET_CONTRACTS_PAGINATED_MUTATION = `${SCOPE}/setContractsPaginated`;
export const SET_CONTRACT_DETAIL_MUTATION = `${SCOPE}/setContractDetail`;
export const SET_CONTRACT_ARE_LOADING_MUTATION = `${SCOPE}/setContractsAreLoading`;
export const SET_CONTRACTS_LOADED_MUTATION = `${SCOPE}/setContractsLoaded`;
export const SET_CONTRACT_IS_LOADING_MUTATION = `${SCOPE}/setContractIsLoading`;
export const SAVE_NEW_CONTRACTS_MUTATION = `${SCOPE}/saveNewContracts`;
export const SET_CONTRACT_IS_CREATING_MUTATION = `${SCOPE}/setContractIsCreating`;
export const SAVE_UPDATED_CONTRACT_MUTATION = `${SCOPE}/saveUpdatedContract`;
export const SET_CONTRACT_IS_UPDATING_MUTATION = `${SCOPE}/setContractIsUpdating`;
export const SET_CONTRACT_ADD_INPUT_MUTATION = `${SCOPE}/setContractAddInput`;
export const SET_CONTRACT_DETAIL_INPUT_MUTATION = `${SCOPE}/setContractDetailInput`;
export const SET_CONTRACT_IS_DELETING_MUTATION = `${SCOPE}/setContractIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
