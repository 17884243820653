<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 row">
                <slot name="title"></slot>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <slot name="breadcrumb"></slot>
                </ol>
            </div>
        </div>
    </div>
</div>