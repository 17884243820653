export const SCOPE = `PMK/LegalOffice`;


export const LEGAL_OFFICE_LIST_GETTER = `${SCOPE}/LEGAL_OFFICE_LIST_GETTER`;
export const LEGAL_OFFICES_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const LEGAL_OFFICE_GETTER = `${SCOPE}/user`;
export const NEW_LEGAL_OFFICE_GETTER = `${SCOPE}/newLegalOffice`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_LEGAL_OFFICES_ACTION = `${SCOPE}/fetchAllLegalOffices`;
export const FETCH_DETAIL_LEGAL_OFFICE_ACTION = `${SCOPE}/fetchDetailLegalOffice`;
export const STORE_LEGAL_OFFICE_ACTION = `${SCOPE}/storeLegalOffice`;
export const UPDATE_LEGAL_OFFICE_ACTION = `${SCOPE}/updateLegalOffice`;
export const DELETE_LEGAL_OFFICE_ACTION = `${SCOPE}/deleteLegalOffice`;
export const ADD_LEGAL_OFFICE_INPUT_ACTION = `${SCOPE}/addLegalOfficeInput`;
export const UPDATE_LEGAL_OFFICE_INPUT_ACTION = `${SCOPE}/updateLegalOfficeInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_LEGAL_OFFICES_MUTATION = `${SCOPE}/setLegalOffices`;
export const SET_LEGAL_OFFICES_PAGINATED_MUTATION = `${SCOPE}/setLegalOfficesPaginated`;
export const SET_LEGAL_OFFICE_DETAIL_MUTATION = `${SCOPE}/setLegalOfficeDetail`;
export const SET_LEGAL_OFFICE_ARE_LOADING_MUTATION = `${SCOPE}/setLegalOfficesAreLoading`;
export const SET_LEGAL_OFFICES_LOADED_MUTATION = `${SCOPE}/setLegalOfficesLoaded`;
export const SET_LEGAL_OFFICE_IS_LOADING_MUTATION = `${SCOPE}/setLegalOfficeIsLoading`;
export const SAVE_NEW_LEGAL_OFFICES_MUTATION = `${SCOPE}/saveNewLegalOffices`;
export const SET_LEGAL_OFFICE_IS_CREATING_MUTATION = `${SCOPE}/setLegalOfficeIsCreating`;
export const SAVE_UPDATED_LEGAL_OFFICE_MUTATION = `${SCOPE}/saveUpdatedLegalOffice`;
export const SET_LEGAL_OFFICE_IS_UPDATING_MUTATION = `${SCOPE}/setLegalOfficeIsUpdating`;
export const SET_LEGAL_OFFICE_ADD_INPUT_MUTATION = `${SCOPE}/setLegalOfficeAddInput`;
export const SET_LEGAL_OFFICE_DETAIL_INPUT_MUTATION = `${SCOPE}/setLegalOfficeDetailInput`;
export const SET_LEGAL_OFFICE_IS_DELETING_MUTATION = `${SCOPE}/setLegalOfficeIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
