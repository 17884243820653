<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} • ${app_title}` : app_title }}</template>
  </metainfo>
  <router-view/>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  data() {
    return {
      app_title: process.env.VUE_APP_TITLE,
    }
  },
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
}
</script>

<style>
.nav-link:hover {
  cursor: pointer;
}
#app {
  position: relative;
  z-index: 1;
}
/*.btn:hover {*/
/*  transform: scale(1.05);*/
/*}*/

td > .fas:hover {
  transform: scale(1.05) !important;
}
i.fas:hover{
  transform: scale(1.05)
}

/*.v-toast {*/
/*  position: relative !important;*/
/*  display: flex;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  padding: 2em;*/
/*  overflow: hidden;*/
/*  z-index: 9552 !important;*/
/*  pointer-events: none;*/
/*}*/

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  color: #2c3e50;*/
/*}*/

/*#nav {*/
/*  padding: 30px;*/
/*}*/

/*#nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

/*#nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/
</style>
