import { calculateWindowSize } from '@/Utils/Helpers';


const state = () => ({
    isSidebarMenuCollapsed: false,
    screenSize: calculateWindowSize(window.innerWidth),
    modalActive: false
});


const getters = {
    isSidebarMenuCollapsed: state => state.isSidebarMenuCollapsed,
    screenSize: state => state.screenSize,
    modalActive: state => state.modalActive
}


const mutations = {
    toggleSidebarMenu: (state) => {
        state.isSidebarMenuCollapsed = !state.isSidebarMenuCollapsed;
    },
    setWindowSize: (state, payload) => {
        state.screenSize = payload;
    },
    setModalActive: (state, payload) => {
        state.modalActive = payload;
    }
};


const actions = {
    toggleSidebarMenu: (context) => {
        context.commit('toggleSidebarMenu');
    },
    setWindowSize: (context, payload) => {
        context.commit('setWindowSize', payload);
    },
    setModalActive: (context, payload) => {
        context.commit('setModalActive', payload);
    }
};


const UI = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default UI;
