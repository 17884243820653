export const SCOPE = `PMK/Department`;


export const DEPARTMENT_LIST_GETTER = `${SCOPE}/DEPARTMENT_LIST_GETTER`;
export const DEPARTMENTS_PAGINATED_DATA_GETTER = `${SCOPE}/departmentsPaginatedData`;
export const DEPARTMENT_GETTER = `${SCOPE}/department`;
export const NEW_DEPARTMENT_GETTER = `${SCOPE}/newDepartment`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_DEPARTMENTS_ACTION = `${SCOPE}/fetchAllDepartments`;
export const FETCH_DETAIL_DEPARTMENT_ACTION = `${SCOPE}/fetchDetailDepartment`;
export const STORE_DEPARTMENT_ACTION = `${SCOPE}/storeDepartment`;
export const UPDATE_DEPARTMENT_ACTION = `${SCOPE}/updateDepartment`;
export const DELETE_DEPARTMENT_ACTION = `${SCOPE}/deleteDepartment`;
export const ADD_DEPARTMENT_INPUT_ACTION = `${SCOPE}/addDepartmentInput`;
export const UPDATE_DEPARTMENT_INPUT_ACTION = `${SCOPE}/updateDepartmentInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_DEPARTMENTS_MUTATION = `${SCOPE}/setDepartments`;
export const SET_DEPARTMENTS_PAGINATED_MUTATION = `${SCOPE}/setDepartmentsPaginated`;
export const SET_DEPARTMENT_DETAIL_MUTATION = `${SCOPE}/setDepartmentDetail`;
export const SET_DEPARTMENT_ARE_LOADING_MUTATION = `${SCOPE}/setDepartmentsAreLoading`;
export const SET_DEPARTMENTS_LOADED_MUTATION = `${SCOPE}/setDepartmentsLoaded`;
export const SET_DEPARTMENT_IS_LOADING_MUTATION = `${SCOPE}/setDepartmentIsLoading`;
export const SAVE_NEW_DEPARTMENTS_MUTATION = `${SCOPE}/saveNewDepartments`;
export const SET_DEPARTMENT_IS_CREATING_MUTATION = `${SCOPE}/setDepartmentIsCreating`;
export const SAVE_UPDATED_DEPARTMENT_MUTATION = `${SCOPE}/saveUpdatedDepartment`;
export const SET_DEPARTMENT_IS_UPDATING_MUTATION = `${SCOPE}/setDepartmentIsUpdating`;
export const SET_DEPARTMENT_ADD_INPUT_MUTATION = `${SCOPE}/setDepartmentAddInput`;
export const SET_DEPARTMENT_DETAIL_INPUT_MUTATION = `${SCOPE}/setDepartmentDetailInput`;
export const SET_DEPARTMENT_IS_DELETING_MUTATION = `${SCOPE}/setDepartmentIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
