<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline1 card-primary1">
                    <div class="card-header">
                        <h3 class="card-title">
                            <slot name="cardTitle"></slot>
                        </h3>

                        <div class="card-tools">
                            <slot name="cardTools"></slot>
                        </div>
                    </div>
                    <div class="card-body card_body p-0">
                        <slot name="cardBody"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>