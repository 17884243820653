<div class="login-box">
    <div v-if="errorStatus" class="alert alert-danger alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
        <h5><i class="icon fas fa-ban"></i> Login!</h5>
        {{ errorMessage }}
    </div>
    <div class="card card-outline card-primary">
        <div class="card-header text-center">
            <a href="/" class="h1">Login <b>PMK</b></a>
        </div>
        <div class="card-body">
            <p class="login-box-msg">Sign in to start your session</p>

            <form @submit.prevent="handleLogin" method="post">
                <div class="input-group mb-3">
                    <input v-model="email" type="email" name="email" class="form-control" placeholder="Email" required
                           autofocus>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input v-model="password" type="password" class="form-control" placeholder="Password" required>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <div class="icheck-primary">
                            <input type="checkbox" id="remember" v-model="remember_me">
                            <label for="remember">
                                Remember Me
                            </label>
                        </div>
                    </div>
                    <div class="col-4">
                        <!--              <button type="submit" class="btn btn-primary btn-block">Sign In</button>-->
                        <app-button
                                class="btn-primary"
                                block="true"
                                type="submit"
                                :loading="isLoading"
                                :flex="true"
                                :disabled="false"
                        >Sign In
                        </app-button>
                    </div>
                </div>
            </form>

            <p class="mb-1">
                <a href="forgot-password.html">I forgot my password</a>
            </p>
        </div>
    </div>
</div>