<content-header>
    <template #title>
        <h1 class="m-0">Contract Types</h1>
    </template>
    <template #breadcrumb>
        <breadcrumb-item>
            <router-link to="/admin">
                Dashboard
            </router-link>
        </breadcrumb-item>
        <breadcrumb-item :active="true">
            Contract Types
        </breadcrumb-item>
    </template>
</content-header>

<page-content>
    <template #cardTitle>
        All contract types
    </template>
    <template #cardTools>
        <app-button
                v-if="canAdd"
                @click="showAddModal()"
                type="button"
                class="btn-outline-primary btn-sm pl-3 pr-3 mr-2"
                style="width: 90px;"
        >Add new
        </app-button>
        <app-button
                type="button"
                class="btn-outline-primary btn-sm pl-3 pr-3"
                style="min-width: 250px;"
                icon="fa-search"
                icon_size="fa-sm"
        >Search
        </app-button>
    </template>
    <template #cardBody>
        <app-loader v-if="isLoadingAll && canShowAll"></app-loader>
        <table v-if="!isLoadingAll && contract_typesPaginatedData"
               class="table table-striped table-hover1 datatable table-borderless">
            <thead>
            <tr>
                <th style="width: 10px">#</th>
                <th>Name</th>
                <th style="width: 140px">More</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in contract_typesPaginatedData.data" :key="index">
                <td>{{ ((query.page*10)-10)+(index+1) }}</td>
                <td>{{ item.name }}</td>
                <td class="more_actions">
                    <img
                            v-if="canShow"
                            @click="showInfoModal(item.id)"
                            class="svg_icon info"
                            src="/img/info.svg"
                    />
                    <img
                            v-if="canEdit"
                            @click="showEditModal(item.id)"
                            class="svg_icon edit"
                            src="/img/edit1.svg"
                    />
                    <img
                            v-if="canDelete"
                            @click="showDeleteModal(item.id)"
                            class="svg_icon delete"
                            src="/img/delete.svg"
                    />
                </td>
            </tr>
            </tbody>
        </table>
        <div v-if="!isLoadingAll && contract_typesPaginatedData !== null"
             class="vertical-center mt-2 mb-2 ml-3">
            <v-pagination
                    v-model="query.page"
                    :pages="contract_typesPaginatedData.pagination.total_pages"
                    :range-size="2"
                    active-color="#DCEDFF"
                    @update:modelValue="getResults"
            />
        </div>
        <div v-if="!canShowAll">
            <p class="text-black text-center m-5">You do not have the required authorization.</p>
        </div>
    </template>
</page-content>

<teleport to="body">
    <div @click="closeModal" class="modal fade" :class="{ 'show': modalAddActive, 'showAddModal': modalAddActive }"
         id="modal-add">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <app-button @click="closeModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </app-button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading && newContractType" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Name:*</td>
                            <td>
                                <input
                                        @keyup.enter="onSubmitAdd"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'name', this.isCreating) }"
                                        name="name"
                                        placeholder="Contract type name"
                                        @input="addContractTypeInputAction"
                                        :value="newContractType.name"
                                        ref="name"
                                        aria-describedby="add-name-error"
                                        required
                                >
                                <span
                                        v-if="checkIfFieldHasErrors(errors, 'name', this.isCreating)"
                                        id="add-name-error"
                                        class="error invalid-feedback"
                                >{{ errors['name'][0] }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                                data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button v-if="!isCreating"
                                @click="onSubmitAdd"
                                type="button"
                                class="btn-primary"
                    >Save
                    </app-button>
                    <app-button v-if="isCreating"
                                class="btn-primary"
                                type="button" disabled>
              <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                        Saving
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="contract_type" @click="closeModal" class="modal fade"
         :class="{ 'show': modalInfoActive, 'showInfoModal': modalInfoActive }" id="modal-info">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Id:</td>
                            <td>{{ contract_type.id }}</td>
                        </tr>
                        <tr>
                            <td>Name:</td>
                            <td>{{ contract_type.name }}</td>
                        </tr>
                        <tr>
                            <td>Created At:</td>
                            <td>{{ new Date(contract_type.created_at).toDateString() }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                                data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                    >Print
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="contract_type" @click="closeModal" class="modal fade"
         :class="{ 'show': modalEditActive, 'showEditModal': modalEditActive }" id="modal-edit">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <app-button @click="closeModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </app-button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Id: *</td>
                            <td><input type="text" class="form-control" name="id" :value="contract_type.id" readonly>
                            </td>
                        </tr>
                        <tr>
                            <td>Name:*</td>
                            <td>
                                <input
                                        @keyup.enter="onSubmitEdit"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'name', this.isUpdating) }"
                                        name="name"
                                        :value="contract_type.name"
                                        @input="updateContractTypeInputAction"
                                        aria-describedby="name-error"
                                        required
                                >
                                <span
                                        v-if="checkIfFieldHasErrors(errors, 'name', this.isUpdating)"
                                        id="name-error"
                                        class="error invalid-feedback"
                                >{{ errors['name'][0] }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                                data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button v-if="!isUpdating"
                                @click="onSubmitEdit"
                                type="button"
                                class="btn-primary"
                    >Save
                    </app-button>
                    <app-button v-if="isUpdating"
                                class="btn-primary"
                                type="button" disabled>
              <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                        Saving
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</teleport>