export const SCOPE = `PMK/ContractType`;


export const CONTRACT_TYPE_LIST_GETTER = `${SCOPE}/CONTRACT_TYPE_LIST_GETTER`;
export const CONTRACT_TYPES_PAGINATED_DATA_GETTER = `${SCOPE}/departmentsPaginatedData`;
export const CONTRACT_TYPE_GETTER = `${SCOPE}/department`;
export const NEW_CONTRACT_TYPE_GETTER = `${SCOPE}/newContractType`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_CONTRACT_TYPES_ACTION = `${SCOPE}/fetchAllContractTypes`;
export const FETCH_DETAIL_CONTRACT_TYPE_ACTION = `${SCOPE}/fetchDetailContractType`;
export const STORE_CONTRACT_TYPE_ACTION = `${SCOPE}/storeContractType`;
export const UPDATE_CONTRACT_TYPE_ACTION = `${SCOPE}/updateContractType`;
export const DELETE_CONTRACT_TYPE_ACTION = `${SCOPE}/deleteContractType`;
export const ADD_CONTRACT_TYPE_INPUT_ACTION = `${SCOPE}/addContractTypeInput`;
export const UPDATE_CONTRACT_TYPE_INPUT_ACTION = `${SCOPE}/updateContractTypeInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_CONTRACT_TYPES_MUTATION = `${SCOPE}/setContractTypes`;
export const SET_CONTRACT_TYPES_PAGINATED_MUTATION = `${SCOPE}/setContractTypesPaginated`;
export const SET_CONTRACT_TYPE_DETAIL_MUTATION = `${SCOPE}/setContractTypeDetail`;
export const SET_CONTRACT_TYPE_ARE_LOADING_MUTATION = `${SCOPE}/setContractTypesAreLoading`;
export const SET_CONTRACT_TYPES_LOADED_MUTATION = `${SCOPE}/setContractTypesLoaded`;
export const SET_CONTRACT_TYPE_IS_LOADING_MUTATION = `${SCOPE}/setContractTypeIsLoading`;
export const SAVE_NEW_CONTRACT_TYPES_MUTATION = `${SCOPE}/saveNewContractTypes`;
export const SET_CONTRACT_TYPE_IS_CREATING_MUTATION = `${SCOPE}/setContractTypeIsCreating`;
export const SAVE_UPDATED_CONTRACT_TYPE_MUTATION = `${SCOPE}/saveUpdatedContractType`;
export const SET_CONTRACT_TYPE_IS_UPDATING_MUTATION = `${SCOPE}/setContractTypeIsUpdating`;
export const SET_CONTRACT_TYPE_ADD_INPUT_MUTATION = `${SCOPE}/setContractTypeAddInput`;
export const SET_CONTRACT_TYPE_DETAIL_INPUT_MUTATION = `${SCOPE}/setContractTypeDetailInput`;
export const SET_CONTRACT_TYPE_IS_DELETING_MUTATION = `${SCOPE}/setContractTypeIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
