import { useMeta } from 'vue-meta'

export default {
    name: "Dashboard",
    setup () {
        useMeta({ title: 'Dashboard' })
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
    }
}