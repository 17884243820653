// import Messages from './Messages/Messages.vue';
// import Notifications from './Notifications/Notifications.vue';
import User from './User/User.vue';

export default {
    name: "Header",
    components: {
        // 'messages-dropdown': Messages,
        // 'notifications-dropdown': Notifications,
        'user-dropdown': User
    },
    methods: {
        onToggleMenuSidebar() {
            this.$emit('toggle-menu-sidebar');
        }
    }
}