export const SCOPE = `PMK/ExecutiveDirector`;


export const EXECUTIVE_DIRECTOR_LIST_GETTER = `${SCOPE}/EXECUTIVE_DIRECTOR_LIST_GETTER`;
export const EXECUTIVE_DIRECTORS_PAGINATED_DATA_GETTER = `${SCOPE}/usersPaginatedData`;
export const EXECUTIVE_DIRECTOR_GETTER = `${SCOPE}/user`;
export const NEW_EXECUTIVE_DIRECTOR_GETTER = `${SCOPE}/newExecutiveDirector`;
export const FIRST_TIME_LOADED_GETTER = `${SCOPE}/firstTimeLoaded`;
export const IS_LOADING_ALL_GETTER = `${SCOPE}/isLoadingAll`;
export const IS_LOADING_GETTER = `${SCOPE}/isLoading`;
export const IS_CREATING_GETTER = `${SCOPE}/isCreating`;
export const CREATED_DATA_GETTER = `${SCOPE}/createdData`;
export const IS_UPDATING_GETTER = `${SCOPE}/isUpdating`;
export const UPDATED_DATA_GETTER = `${SCOPE}/updatedData`;
export const IS_DELETING_GETTER = `${SCOPE}/isDeleting`;
export const DELETED_DATA_GETTER = `${SCOPE}/deletedData`;
export const ERRORS_GETTER = `${SCOPE}/errors`;


export const FETCH_ALL_EXECUTIVE_DIRECTORS_ACTION = `${SCOPE}/fetchAllExecutiveDirectors`;
export const FETCH_DETAIL_EXECUTIVE_DIRECTOR_ACTION = `${SCOPE}/fetchDetailExecutiveDirector`;
export const STORE_EXECUTIVE_DIRECTOR_ACTION = `${SCOPE}/storeExecutiveDirector`;
export const UPDATE_EXECUTIVE_DIRECTOR_ACTION = `${SCOPE}/updateExecutiveDirector`;
export const DELETE_EXECUTIVE_DIRECTOR_ACTION = `${SCOPE}/deleteExecutiveDirector`;
export const ADD_EXECUTIVE_DIRECTOR_INPUT_ACTION = `${SCOPE}/addExecutiveDirectorInput`;
export const UPDATE_EXECUTIVE_DIRECTOR_INPUT_ACTION = `${SCOPE}/updateExecutiveDirectorInput`;
export const SET_ERRORS_ACTION = `${SCOPE}/setErrors`;


export const SET_EXECUTIVE_DIRECTORS_MUTATION = `${SCOPE}/setExecutiveDirectors`;
export const SET_EXECUTIVE_DIRECTORS_PAGINATED_MUTATION = `${SCOPE}/setExecutiveDirectorsPaginated`;
export const SET_EXECUTIVE_DIRECTOR_DETAIL_MUTATION = `${SCOPE}/setExecutiveDirectorDetail`;
export const SET_EXECUTIVE_DIRECTOR_ARE_LOADING_MUTATION = `${SCOPE}/setExecutiveDirectorsAreLoading`;
export const SET_EXECUTIVE_DIRECTORS_LOADED_MUTATION = `${SCOPE}/setExecutiveDirectorsLoaded`;
export const SET_EXECUTIVE_DIRECTOR_IS_LOADING_MUTATION = `${SCOPE}/setExecutiveDirectorIsLoading`;
export const SAVE_NEW_EXECUTIVE_DIRECTORS_MUTATION = `${SCOPE}/saveNewExecutiveDirectors`;
export const SET_EXECUTIVE_DIRECTOR_IS_CREATING_MUTATION = `${SCOPE}/setExecutiveDirectorIsCreating`;
export const SAVE_UPDATED_EXECUTIVE_DIRECTOR_MUTATION = `${SCOPE}/saveUpdatedExecutiveDirector`;
export const SET_EXECUTIVE_DIRECTOR_IS_UPDATING_MUTATION = `${SCOPE}/setExecutiveDirectorIsUpdating`;
export const SET_EXECUTIVE_DIRECTOR_ADD_INPUT_MUTATION = `${SCOPE}/setExecutiveDirectorAddInput`;
export const SET_EXECUTIVE_DIRECTOR_DETAIL_INPUT_MUTATION = `${SCOPE}/setExecutiveDirectorDetailInput`;
export const SET_EXECUTIVE_DIRECTOR_IS_DELETING_MUTATION = `${SCOPE}/setExecutiveDirectorIsDeleting`;
export const SET_ERRORS_MUTATION = `${SCOPE}/setErrors`;
