<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 row">
                <h1 class="m-0">Companies</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/admin">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active">Companies</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline1 card-primary1">
                    <div class="card-header">
                        <h3 class="card-title">All companies</h3>

                        <div class="card-tools">
                            <app-button
                                    v-if="canAdd"
                                    @click="showAddModal()"
                                    type="button"
                                    class="btn-outline-primary btn-sm pl-3 pr-3 mr-2"
                                    style="width: 90px;"
                            >Add new
                            </app-button>
                            <app-button
                                    v-if="canShowAll"
                                    type="button"
                                    class="btn-outline-primary btn-sm pl-3 pr-3"
                                    style="min-width: 250px;"
                                    icon="fa-search"
                                    icon_size="fa-sm"
                            >Search
                            </app-button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <!--              <div v-if="isLoadingAll && !firstTimeLoaded" class="text-center mt-5 mb-5">-->
                        <app-loader v-if="isLoadingAll && canShowAll"></app-loader>
                        <table v-if="!isLoadingAll && companiesPaginatedData"
                               class="table table-striped table-hover1 datatable table-borderless">
                            <thead>
                            <tr>
                                <th style="width: 10px">#</th>
                                <th>Name</th>
                                <th style="width: 140px">More</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in companiesPaginatedData.data" :key="index">
                                <td>{{ ((query.page*10)-10)+(index+1) }}</td>
                                <td>{{ item.name }}</td>
                                <td class="more_actions">
                                    <img
                                            v-if="canShow"
                                            @click="showInfoModal(item.id)"
                                            class="svg_icon info"
                                            src="/img/info.svg"
                                    />
                                    <img
                                            v-if="canEdit"
                                            @click="showEditModal(item.id)"
                                            class="svg_icon edit"
                                            src="/img/edit1.svg"
                                    />
                                    <img
                                            v-if="canDelete"
                                            @click="showDeleteModal(item.id)"
                                            class="svg_icon delete"
                                            src="/img/delete.svg"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-if="!isLoadingAll && companiesPaginatedData !== null"
                             class="vertical-center mt-2 mb-2 ml-3">
                            <v-pagination
                                    v-model="query.page"
                                    :pages="companiesPaginatedData.pagination.total_pages"
                                    :range-size="2"
                                    active-color="#DCEDFF"
                                    @update:modelValue="getResults"
                            />
                        </div>
                        <div v-if="!canShowAll">
                            <p class="text-black text-center m-5">You do not have the required authorization.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<teleport to="body">
    <div @click="closeModal" class="modal fade" :class="{ 'show': modalAddActive, 'showAddModal': modalAddActive }"
         id="modal-add">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <app-button @click="closeModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </app-button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading && newCompany" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Name:*</td>
                            <td>
                                <input
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'name') }"
                                        name="name"
                                        placeholder="Company name"
                                        @input="addCompanyInputAction"
                                        :value="newCompany.name"
                                        aria-describedby="add-name-error"
                                        ref="name"
                                        required
                                        @keyup.enter="onSubmitAdd"
                                >
                                <span v-if="checkIfFieldHasErrors(errors, 'name')" id="add-name-error"
                                      class="error invalid-feedback">{{ errors['name'][0] }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                                data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button v-if="!isCreating"
                                @click="onSubmitAdd"
                                type="button"
                                class="btn-primary"
                    >Save
                    </app-button>
                    <app-button v-if="isCreating"
                                class="btn-primary"
                                type="button" disabled>
              <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                        Saving
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="company" @click="closeModal" class="modal fade"
         :class="{ 'show': modalInfoActive, 'showInfoModal': modalInfoActive }" id="modal-info">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Id:</td>
                            <td>{{ company.id }}</td>
                        </tr>
                        <tr>
                            <td>Name:</td>
                            <td>{{ company.name }}</td>
                        </tr>
                        <tr>
                            <td>Departments:</td>
                            <td v-if="company.departments">
                                <span v-for="item in company.departments" :key="item.id" class="badge badge-info mr-1">
                                    {{ item.name }}
                                </span>
                                <span v-if="company.departments.length == 0" class="badge badge-info mr-1">
                                    NO DEPARTMENT
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Created At:</td>
                            <td>{{ new Date(company.created_at).toLocaleString() }}</td>
                        </tr>
                        <tr>
                            <td>Last modified at:</td>
                            <td>{{ new Date(company.updated_at).toLocaleString() }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                                data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button @click="closeModal"
                                type="button"
                                class="btn-default"
                    >Print
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="company" @click="closeModal" class="modal fade"
         :class="{ 'show': modalEditActive, 'showEditModal': modalEditActive }" id="modal-edit">
        <div class="modal-dialog" style="max-width: 50%;">
            <div @click.stop="modalContent" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ this.appTitle }} • {{ this.appDescription }}</h4>
                    <app-button @click="closeModal"
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </app-button>
                </div>
                <div class="modal-body p-0" style="height:auto; overflow:auto;">
                    <table v-if="!isLoading" id="printTable"
                           class="table table-hover table-borderless table-striped p-0">
                        <thead>
                        <tr>
                            <th>Col</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Id:*</td>
                            <td><input type="text" class="form-control" name="id" :value="company.id" readonly></td>
                        </tr>
                        <tr>
                            <td>Name:*</td>
                            <td>
                                <input
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'name') }"
                                        name="name"
                                        :value="company.name"
                                        @input="updateCompanyInputAction"
                                        aria-describedby="name-error"
                                        required
                                        @keyup.enter="onSubmitEdit"
                                >
                                <span v-if="checkIfFieldHasErrors(errors, 'name')" id="name-error"
                                      class="error invalid-feedback">{{
                                        errors['name'][0]
                                    }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <app-loader v-if="isLoading"></app-loader>
                </div>
                <div class="modal-footer justify-content-between">
                    <app-button
                            @click="closeModal"
                            type="button"
                            class="btn-default"
                            data-dismiss="modal"
                    >Close
                    </app-button>
                    <app-button v-if="!isUpdating"
                                @click="onSubmitEdit"
                                type="button"
                                class="btn-primary"
                    >Save
                    </app-button>
                    <app-button v-if="isUpdating"
                                class="btn-primary"
                                type="button" disabled>
              <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                        Saving
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</teleport>